.header {
  font-family: $roboto;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 1.2px;
  line-height: 1.22;
  color: $mid-grey;
  margin-top: 30px;

  @media screen and (max-width: $breakpoint-mobile) {
    margin-top: 0;
  }
}

.section-header  {
  font-family: $news-cycle;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: #fff;
}

h1 {
  color: $mid-grey;
  font-family: $news-cycle;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.67;
  margin-bottom: 0;
}

h4 {
  color: $mid-grey;
  font-family: $roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.7px;
  line-height: normal;
  margin-bottom: 0;
}

p {
  font-family: $roboto;
  font-weight: 300;
  line-height: 22px;
  font-size: 15px;
  letter-spacing: 1px;
  font-color: $mid-grey;
}
