.video-control {
  // margin-top: 20px;
}

.video-js {
  margin: 25px 0;
}

_::-webkit-full-page-media, _:future, :root .take-a-video {
  display: none;
}

.show-on-apple {
  display: none;
}

_::-webkit-full-page-media, _:future, :root .show-on-apple {
  display: block;
}
