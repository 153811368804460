.custom-jumbotron {
  justify-content: center;
  align-items: center;
  background-color: $brand-blue-light;
  display: flex;
  min-height: 333px;
  text-align: center;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 20px;
    min-height: 200px;
  }
}

.custom-jumbotron--text {
  color: #fff;
  font-family: $news-cycle;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.76px;
  text-align: left;
  line-height: 50px;
  width: 70%;
  margin: 0 auto;

  @media screen and (max-width: $breakpoint-mobile) {
    font-size: 35px;
    font-weight: 900;
    width: 100%;
    line-height: 40px;
    padding-left: 20px;
  }
}
