.task_list_item__container {
  cursor: pointer;
}

.task_list_item__detail_column {
  border-bottom: 1px solid $light-grey;
}

.task_list_item__icon {
  background-color: $white;
  border: 2px solid $brand-blue-light;
  border-radius: 5px;
  height: 49px;
  width: 49px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $breakpoint-mobile) {
    height: 20px;
    width: 20px;
  }
}

.task_list_item__icon_column {
  // @media screen and (max-width: $breakpoint-mobile) {
  //   display: flex;
  //   justify-content: center;
  // }
}

.task_list_item__icon_image {
  height: 50%;
  width: 50%;
}

.task_list_item__name {
  @media screen and (max-width: $breakpoint-mobile) {
    margin-bottom: 10px;
  }
}

.task_list_item__name--completed {
  @extend .task_list_item__name;
  text-decoration: line-through;
}

.task_list_item__number {
  width: 11px;
  height: 19px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  color: $brand-blue-light;
}

.task_list_item__number_column {
  @media screen and (max-width: $breakpoint-mobile) {
    display: none;
  }
}
