.task-form__block {
  background-color: $white;
}

.task-form__block--inner {
  margin: 0 25px;
  border-bottom: 1px solid $light-border-grey;
  padding-bottom: 25px;
}

.task-form__block-content {
  color: #000;
  font-family: $roboto;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.86px;
  line-height: 26px;
  padding-top: 20px;
}

.task-form__text-area {
  width: 50%;
  height: 125px;

  @media screen and (max-width: $breakpoint-mobile) {
    width: 100%;
  }
}

.task-form__submit-container {
  text-align: center;
  padding: 20px 0;
  background-color: $white;
  min-width: 165px;
}

.task-form__media-capture-button-container {
  margin: 5px;
}

