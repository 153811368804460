.primary-app-btn {
  background-color: $brand-blue;
  font-family: $roboto;
  font-weight: 500;
  color: #fff;
  min-width: 196px;
  border-radius: 20px;
  min-height: 40px;

  &:hover {
    text-decoration: none;
    color: #fff;
  }
}

.file-input-btn::-webkit-file-upload-button {
  visibility: hidden;
}

.file-input-btn::before {
  content: 'Choose File';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

.btn-file {
  background: linear-gradient(to left, rgba(241, 241, 241, 0.59), #ffffff);
  border-radius: 3px;
  border: 1px solid rgba(151, 151, 151, 0.42);
  height: 28px;
  min-width: 125px;

  // Button text style
  font-color: $mid-grey;
  font-family: $roboto;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 22px;
}

.record-btn {
  width: 25px;
  height: 25px;
  margin: 5px;

  &:hover {
    cursor: pointer;
  }
}
