.study_list_item__container {
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09);
  cursor: pointer;
  padding: 0;
}

.study_list_item__content {
  background-color: $white;
  border-top: 1px solid $light-grey;
  padding: 25px 17px;
  height: 102px;
}

.study_list_item__image, .study_list_item__image_mobile {
  height: 190px;
  width: 100%;
}
