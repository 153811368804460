.task_list__column {
  margin-bottom: 18px;
}

.task_list__container {
  padding: 0;
}

.task_list__no_results {
  margin: 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
}
