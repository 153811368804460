.notifications-header {
  margin-top: 20px;
}

.notifications-container {
  background-color: $white;
  padding: 20px;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 10px 0;
  }
}

.notification-row {
  padding-top: 20px;
}

.notification-row__content {
  border-bottom: 1px solid $light-border-grey;
}

.notification-row__subject {
  color: $black-kinda;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.notification-row__sub-header {
  font-family: $roboto;
  font-size: 13px;
  color: $metallic-grey;
  letter-spacing: 0.22px;
  font-weight: 500;
}

.notification-row__delete-container {
  display: flex;
  justify-content: flex-end;
}

.notification-row__delete {
  color: $navy-dark;

  &:hover {
    cursor: pointer;
  }
}

.notifications-empty {
  text-align: center;
}
