.success_display {

}

.success_display__container {
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09);
  max-width: 750px;
}

.success_display__wrapper {
  background-color: $background-periwinkle;
  padding: 42px 137px;
  text-align: center;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 20px 10px;
  }
}

.success_display__button {
  font-size: 15px;
  padding-top: 10px;
  width: 242px;
  height: 49px;
  border-radius: 24.5px;
}

.success_display__button_row {
  padding: 63px 0;
}

.success_display__heading_text_row {
  color: $brand-blue;
  font-family: $roboto;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 1px;
  margin-top: 45px;
  margin-bottom: 10px;
}

.success_display__icon {
  height: 106px;
  width: 106px;
}

.success_display__icon_row {
  background-color: $brand-blue-light;
  padding: 60px 0;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 30px 0;
  }
}

.success_display__sub_text_row {
  font-family: $roboto;
  font-size: 15px;
  line-height: 1.47;
  letter-spacing: 1px;
}
