.review-new {
  border-radius: 7px;
  margin-top: 40px;
}

.review-new__header {
  background-color: $brand-blue-light;
  height: 77px;
  display: flex;
  align-items: center;
  padding: 0 2%;
  border-radius: 7px 7px 0 0;
}

.review-new__form-block {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 20px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09);
}

.share-check-label {
  margin-top: 6px;
  margin-right: 6px;
}

.review-submit-btn {
  margin: 0 auto;
}

.media-preview {
  @media screen and (max-width: $breakpoint-mobile) {
    width: 100%;
  }
}
