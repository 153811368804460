.content-container {
  border-radius: 7px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09);
}

.content-container > div {
  &:last-child {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
}

.content-container__header {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: $brand-blue-light;
  padding: 20px;
}

.content-container__header-subtitle {
  color: $white;
  font-family: $roboto;
  font-weight: 500;
  letter-spacing: 0.86px;
}

.content-container__header-title {
  color: $white;
  font-family: $news-cycle;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 0.5px;
  line-height: 1.2;
}