.user-profile {
  margin-top: 50px;
}

.user-profile__header {
  margin-bottom: 20px;
}

.avatar-container {
  display: flex;
  flex-direction: column;
  max-width: 175px;
  padding: 5% 0 0 30%;
  align-items: center;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 0;
    align-items: flex-start;
  }
}

.avatar-container__file-input-container {
  margin-top: 20px;
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 75px;
}

.sign-out {
  font-family: $roboto;
  font-weight: 500;
  font-size: 18px;
  color: $mid-grey;
  border-bottom: 3px solid $yellow;
}
