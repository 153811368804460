.app-modal__close {
  position: absolute;
  top: 10px;
  font-size: 20px;
  right: 15px;
  color: $mid-grey;
  z-index: 1;

  &:hover {
    color: $mid-grey;
  }
}

.app-modal__container--inner {
  text-align: center;
}

.app-modal__header {
  font-family: $news-cycle;
  font-weight: 700;
  letter-spacing: 0.54px;
  font-size: 30px;
  color: $mid-grey;
  margin-top: 11px;
  margin-bottom: 14px;
}

.icon-container {
  align-items: center;
  border-radius: 5px;
  border: 1px solid $brand-blue-light;
  display: flex;
  height: 48px;
  justify-content: center;
  margin: 0 auto;
  width: 54px;
}

.modal-footer {
  justify-content: center;
}

label {
}
