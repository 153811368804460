.audio_button {
  background-color: $white;
  border: 2px solid $brand-blue-light;
  border-radius: 6px;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 11px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.audio_button:not(.audio_buton__disabled) {
  &:hover {
    cursor: pointer;
  }
}

.audio_button:last-of-type {
  margin-right: 0;
}

.audio_buton__disabled {
  opacity: 0.5;
}

.audio_button__icon {
  display: flex;
  height: auto;
  width: 15px;
}
