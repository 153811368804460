// COLORS

$alert-red: #ff7b74;
$background-periwinkle: #f8fbfe;
$black-kinda: #292929;
$brand-blue-light: #58bafa;
$brand-blue: #2a7de1;
$dark-grey: #404040;
$light-border-grey: #d0d0d0;
$light-grey: #ececec;
$mid-grey: #5d5d5d;
$mid-light-grey: #dedede;
$navy-dark: #004363;
$red: #e12a2a;
$metallic-grey: #666666;
$white: #ffffff;
$yellow: #ffc50d;
// FONTS
$roboto: 'Roboto', sans-serif;
$news-cycle: 'News Cycle', sans-serif;

// BREAKPOINTS
$breakpoint-mobile: 768px;
$breakpoint-desktop: 992px;

