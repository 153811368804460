.alert__button {
  height: 16px;
  width: 16px;
}

.alert__button_column {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: $breakpoint-mobile) {
    flex: 2;
    max-width: none;
  }
}

.alert__container {
  background-color: $brand-blue-light;
  border-radius: 8px;
  box-shadow: 0 2px 38px 0 rgba(0, 0, 0, 0.11);
  color: $white;
  font-family: $news-cycle;
  max-width: 575px;
  padding: 20px 25px;
}

.alert__container--error {
  @extend .alert__container;
  background-color: $alert-red;
}

.alert__container--success {
  @extend .alert__container;
  background-color: $yellow;
}

.alert__icon {
  height: 50px;
  width: 50px;

  @media screen and (max-width: $breakpoint-mobile) {
    display: none;
  }
}

.alert__icon_column {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: $breakpoint-mobile) {
    display: none;
  }
}

.alert__message_column {
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $breakpoint-mobile) {
    flex: 10;
    max-width: none;
  }
}

.alert__wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-bottom: 70px;
}
