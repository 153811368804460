$footer-height: 65px;

.hoot-nav {
  padding: 30px 5%;
  background-color: #fff;

  @media screen and (max-width: $breakpoint-mobile) {
    display: none;
  }
}

.hoot-nav--inner-row {
  justify-content: flex-end;
}

.nav--link {
  color: $mid-grey;
  font-family: $roboto;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.32px;
  margin: 0 5px;
}

.nav--link__active:after {
  background-color: $yellow;
  border-radius: 3px;
  content: "";
  display: block;
  height: 3px;
  margin: 0 auto;
  width: 60%;
}

.nav__active-border {
  background-color: $yellow;
  height: 3px;
  margin: 0 auto;
  width: 85%;
  border-radius: 2px;
}

.layout-container {
  margin-bottom: -50px;
}

.push {
  height: $footer-height;
  display: none;

  @media screen and (max-width: $breakpoint-mobile) {
    display: block;
  }
}

.mobile-nav {
  background-color: #fff;
  bottom: 0;
  display: none;
  height: $footer-height;
  left: 0;
  position: fixed;
  width: 100%;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid $light-grey;

  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  @media screen and (max-width: $breakpoint-mobile) {
    left: 0px;
    display: flex;
  }
}

.mobile-nav--link {
  color: $mid-grey;
  font-family: $roboto;
  font-size: 13px;
  padding-top: 20px;
  text-align: center;
}

.mobile-nav--link__active {
  color: $brand-blue-light;
}

.mobile-top-nav{
  background-color: $white;
  padding: 15px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09);
  display: none;

  @media screen and (max-width: $breakpoint-mobile) {
    display: block;
  }
}

