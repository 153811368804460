select[name="dial_code"] {
  padding: 0 5px 0 5px;
}

.Form__formContainer {
  text-align: center;
  margin-top: 20px;
  margin: 5% auto 50px;
}

.Form__formSection {
  position: relative;
  margin-bottom: 20px;
  background-color: white;
  padding: 35px 40px;
  text-align: left;
  display: inline-block;
  min-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}

.Section__sectionHeader {
  color: rgb(42, 125, 225);
  font-size: 20px;
  letter-spacing: 0.96px;
  font-family: $roboto;
  font-weight: 500;
  margin-bottom: 24px;
}

.Section__sectionBody {
  margin-bottom: 30px;
}

.Form__formField {
  color: rgb(64, 64, 64);
  letter-spacing: 1.07px;
  font-family: $roboto;
  font-weight: 500;
  margin-bottom: 22px;
}

.Input__inputLabel {
  font-size: 14px;
  margin-bottom: 8px;
}

.Input__input {
  display: block;
  width: 100%;
  padding: 15px;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 10px;
  height: 30px;
}

.Section__sectionFooterPrimaryContent {
  display: block;
  margin: 0 auto;
  width: 60%;
}

.Button__button {
  background-color: rgb(42, 125, 225);
  border-radius: 24.5px;
  display: block;
  letter-spacing: 0.8px;
  padding: 14px 0;
  width: 100%;
  font-size: 12px;
  font-family: $roboto;
  font-weight: 500;
  color: white;

  &:disabled {
    opacity: 0.6;
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    opacity: 0.8;
  }
}

.Section__sectionFooterSecondaryContent {
  color: black;
  display: block;
  letter-spacing: 0.76px;
  margin: 20px;
  font-family: $roboto;
  text-align: center;
}

.Label__label {
  color: black;
  display: inline;
  font-family: $roboto;
  letter-spacing: 0.76px;
  font-size: 12px;
  font-weight: 200;
  margin: 0 10px;
}

.Button__termsBtn {
  padding: 0 0 2px 2px;
  font-size: 12px;

  &:hover {
    text-decoration: none;
  }
}