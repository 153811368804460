.study_show__button_row {

}

.study_show__button_column_top {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: $breakpoint-mobile) {
    justify-content: center;
    margin-top: 24px;
  }
}

.study_show__button_column_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.study_show__container {
  padding-top: 25px;
  margin-bottom: 50px;

  @media screen and (max-width: $breakpoint-mobile) {
    margin-bottom: 25px;
  }
}

.study_show__content_container {
  background-color: $white;
  border-radius: 7px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09);
  padding: 32px 48px;
}

.study_show__content_row {
  margin-bottom: 19px;
}

.study_show__details_row {
  margin-bottom: 15px;
}

.study_show__divider {
  border-bottom: 1px solid $light-grey;
  margin-bottom: 25px;
  display: block;
  height: 2px;
}

.study_show__header_row {
  margin-bottom: 15px;
}

.study_show__image, .study_show__image_mobile {
  border-radius: 7px;
  height: auto;
  width: 100%;
}

.study_show__image, .study_list_item__image {
  @media screen and (max-width: $breakpoint-mobile) {
    display: none;
  }
}

.study_show__image_mobile, .study_list_item__image_mobile {
  @media screen and (min-width: $breakpoint-mobile) {
    display: none;
  }
}

.study_show__label {
  font-weight: bold;
}

.study_show__more_details_row {
  margin-bottom: 47px;
}

.study_show__name_row {
  margin-bottom: 22px;

}
