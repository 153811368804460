.audio_modal {

}

.audio_modal__heading {
  margin-top: 30px;
  margin-bottom: 35px;
  text-align: center;
}

.audio_modal__recorder {
  display: none;
}

.audio_modal__recording_icon {
  margin-right: 7px;
  padding-bottom: 7px;
}

.audio_modal__media_buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 46px;
}

.audio_modal__recording_label_container {
  text-align: center;
}

.audio_modal__recording_label {
  display: inline-block;
  font-size: 24px;
  margin-bottom: 52px;
}

.audio_modal__recording_label--active {
  @extend .audio_modal__recording_label;
  color: $red;
}

.audio_modal__recording_label--inactive {
  @extend .audio_modal__recording_label;
  color: $brand-blue;
}

.audio_modal__time {
  color: $brand-blue;
  font-family: $roboto;
  font-size: 45px;
  font-weight: normal;
  letter-spacing: 2.2px;
  line-height: 0.58;
  text-align: center;
  margin-bottom: 52px;
  height: 30px;
}
