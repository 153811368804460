.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;

  h1 {
    margin: 20px 0;
  }
}