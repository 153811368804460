.task_view_heading__container--clickable {
  cursor: pointer;
}

.task_view_heading__icon {
  margin-bottom: 11px;
}

.task_view_heading__icon_container {
  display: inline-block;
  margin-right: 10px;
}

.task_view_heading__text {
  display: inline-block;
  margin-bottom: 30px;
}
