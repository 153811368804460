.dashboard-container {
  margin-top: 35px;
  margin-bottom: 35px;
  width: 90%;


  @media screen and (max-width: $breakpoint-mobile) {
    width: 90%;
  }
}

.dashboard-title {
  padding-left: 15px;
}

.dashboard__column_wrapper {
  margin: 10px 0;

  @media screen and (max-width: $breakpoint-mobile) {
    padding-left: 5px;
    padding-right: 5px;
  }
  width: 50%;
}

.dashboard__tile_container {
  padding-left: 0px;
  margin-left: 0px;
  padding-right: 0px;
  width: 100%;
}

.dashboard-btn {
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid $light-grey;
  display: flex;
  flex-direction: column;
  height: 165px;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

  min-width: 165px;

  @media screen and (max-width: $breakpoint-mobile) {

    min-width: 130px;
    height: 130px;

  }

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.6;
    cursor: default;
  }

  @media screen and(min-width: $breakpoint-desktop) {
    min-width: 165px;
  }
}

.dashboard-btn__description {
  color: $metallic-grey;
  font-family: $roboto;
  font-size: 15px;
  font-weight: 500;
  margin-top: 20px;
}

.item-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
