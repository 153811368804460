.study_enrollment_form__button {
  height: 40px;
  font-size: 15px;
}

.study_enrollment_form__button_column {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 10px 0;
    justify-content: center;
  }
}

.study_enrollment_form__container {
  background-color: $white;
  padding: 25px;
}

.study_enrollment_form__input {
  border-radius: 3px;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.12);

  height: 40px;
  width: 100%;
}

.study_enrollment_form__input_column {
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 10px 0;
  }
}
