.task_list_section__column {
  margin-bottom: 18px;
}

.task_list_section__container {
  padding: 0;
}

.task_list_section__heading {
  margin-bottom: 37px;
}
