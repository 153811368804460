textarea {
  border-radius: 3px;
  border: 1px solid rgba(139, 145, 153, 0.25);
  font-family: $roboto;
  min-height: 138px;
  padding: 10px;
}

input {
  border-radius: 3px;
  border: 1px solid rgba(139, 145, 153, 0.25);
  font-family: $roboto;
  min-height: 30px;
  padding: 0 5px;
}

.form-block {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.input-label {
  font-family: $roboto;
  font-weight: 500;
  color: $dark-grey;
  letter-spacing: 1.1px;
  line-height: 1.38;

  &.required::after {
    color: $red;
    content: " *Required";
    float: right;
    font-size: 10px;
    font-weight: 200;
  }
}

.form-block-title {
  color: $mid-grey;
  font-family: $news-cycle;
  font-weight: 700;
  letter-spacing: 0.54px;
  font-size: 30px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

select {
  border-radius: 3px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 10px 0;
  height: 30px;

  @media screen and (max-width: $breakpoint-mobile) {
    margin: 5px 0 5px 0;
    padding: 0;
  }
}

.check-box {
  @media screen and (max-width: $breakpoint-mobile) {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
}

.date-select {
  width: 100%;
}

.check-box__label {
  color: $black-kinda;
  font-family: $roboto;
  font-size: 15px;
  font-weight: 300;
  margin: 6px 0 6px 10px;
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-box__label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 12.5px;
  background-color: #fff;
  border: 2px solid $brand-blue-light;
}

.check-box__label:hover input ~ .checkmark {
  // background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: $brand-blue-light;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tab-outer-form-block {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 20px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.09);
}
