.task_show_content__body_container {
  background-color: $white;
  padding: 35px;
}

.task_show_content__button_column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.task_show_content__container {
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09);
  overflow: hidden;
}

.task_show_content__description {
  margin-bottom: 35px;
}

.task_show_content__divider {
  border-bottom: 1px solid $light-grey;
  margin-bottom: 25px;
  display: block;
  height: 2px;
}

.task_show_content__heading_container {
  background-color: $brand-blue-light;
  padding: 35px;

  h1 {
    color: $white;
  }

  h4 {
    color: $white;
  }
}

.task_show_content__instruction_label {
  font-weight: bold;
  margin-right: 10px;
}

.task_show_content__requirement_column {
  margin-bottom: 35px;

  @media screen and (max-width: $breakpoint-mobile) {
    margin-bottom: 20px;
  }
}

.task_show_content__requirement_heading {
  font-weight: bold;
  margin-bottom: 35px;
}

.task_show_content__requirement_image_container {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;

  @media screen and (max-width: $breakpoint-mobile) {
    margin-bottom: 0;
  }
}

.task_show_content__requirement_sub_column {
  display: flex;
  justify-content: center;
}
