.tab-view {
  background-color: $white;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09);
  padding: 15px;
}

.tab-view__header {
  margin: 0;
}

.tab-view__tab {
  align-items: center;
  background-color: rgba(42, 125, 225, 0.1);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  height: 61px;
  justify-content: center;
  min-width: 150px;
  padding: 0 30px;
  text-align: center;
  flex-wrap: nowrap;

  &:hover {
    cursor: pointer;

    &:not(.tab-view__tab--active) {
      opacity: .8;
    }
  }

  &:not(:first-of-type) {
    margin: 0 6px;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 0 5px;
    min-width: 0;
    max-width: 100px;
  }
}

.tab-view__tab--active {
  background-color: $white;
  box-shadow: 2px -2px 2px 0 rgba(0, 0, 0, 0.09);
}

.tab-view__tab-text {
  color: $dark-grey;
  font-family: $roboto;
  font-weight: 500;
  margin: 0;
}

